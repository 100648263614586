export function getDate(
  date,
  currentLanguage = "en",
  { abbreviated = false } = {}
) {
  const newDate = new Date(date);

  const dateFormat = {
    en: "en-GB",
    fr: "fr-FR",
    es: "es-ES",
  };

  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  if (currentLanguage === "es" && abbreviated) {
    const month = new Intl.DateTimeFormat(dateFormat["es"], {
      month: "long",
    }).format(newDate);
    const day = new Intl.DateTimeFormat(dateFormat["es"], {
      day: "numeric",
    }).format(newDate);
    return `${day} ${month}`;
  }

  return new Intl.DateTimeFormat(
    dateFormat[currentLanguage],
    dateOptions
  ).format(newDate);
}

export function getTime(date, duration, currentLanguage = "en") {
  const startTime = new Date(date);
  const endTime = new Date(startTime.getTime() + duration * 60 * 60 * 1000);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const format = {
    en: "en-GB",
    fr: "fr-FR",
    es: "es-ES",
  };

  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  const startOptions = { ...options };
  const endOptions = { ...options, timeZone, timeZoneName: "short" };

  const startFormatter = new Intl.DateTimeFormat(
    format[currentLanguage],
    startOptions
  );
  const endFormatter = new Intl.DateTimeFormat(
    format[currentLanguage],
    endOptions
  );

  const begins = startFormatter.format(startTime);
  const ends = endFormatter.format(endTime);
  return [begins, ends];
}

export const toDateString = (date) => new Date(date).toDateString();

/**
 * Takes an array of event objects and returns an object with keys
 * consisting of date strings with values of arrays of event objects
 */
export const groupEventsByDate = (events) =>
  events.reduce((accumulator, current) => {
    const event = toDateString(current.time);
    if (!accumulator.hasOwnProperty(event)) {
      accumulator[event] = [];
    }
    accumulator[event].push(current);
    accumulator[event].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    );
    return accumulator;
  }, {});

/**
 * Takes a date string and returns time remaining object
 *
 */
export function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}
