import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { startCase } from "lodash";
import { Link, IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import { StaticQuery, graphql } from "gatsby";
import { color } from "src/styled/variables";
import Polygon from "src/components/polygon";
import { getDate, getTime } from "src/utils/dates";
import clock from "src/images/icons/clock-icon.svg";
import media from "src/styled/responsive";

const { ilo_yellow, ilo_dark_blue } = color;

const Section = styled.section`
  margin: 1rem 0;
  padding: 2rem;

  ${media.sm`
    margin: 0 0 2rem;
  `}

  ${media.xs`
    padding: 0;
  `}
`;

const EventGroup = styled.article`
  width: 100%;
  margin: 0 0 3rem;
`;

const Event = styled.div`
  margin: 0 0 3rem;
`;

const EventDate = styled.h4`
  color: ${ilo_dark_blue};

  &:after {
    content: "";
    width: 100%;
    height: 6px;
    display: block;
    margin: 5px 0 0;
    background-color: ${ilo_yellow};
  }
`;

const Details = styled.div`
  margin: 0 0 0 5rem;
  padding: 0 0 0 1rem;
  border-left: 1px solid ${ilo_dark_blue};

  ${media.xs`
    margin: 0 0 0 1rem;

    h5 {
      font-size: 1rem;
      margin: 0 0 0.6rem;
    }

    p {
      font-size: 0.9rem;
      margin: 0 0 1rem;
    }

  `}

  p {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;

    &:before {
      content: "";
      background-image: url(${clock});
      background-repeat: no-repeat;
      width: 1rem;
      height: 1rem;
      position: relative;
      background-size: contain;
      margin: 0 5px 0 0;
    }

    &:after {
      content: "*";
      margin: 0 0 0 3px;
    }
  }

  .event-agenda {
    margin: 1rem 0 0;
    height: 1rem;
  }
`;

function Events({ data, title, global }) {
  const intl = useIntl();

  // Show Global Events or Regional Events together
  const displayEvents = data.filter(({ region }) => {
    if (region) {
      return global
        ? region.toLowerCase() === "global"
        : region.toLowerCase() !== "global";
    }
    return false;
  });

  // Format times
  const displayTimes = (times = []) => `${times[0]} - ${times[1]}`;

  // Group events together by date
  const groupedEvents = displayEvents.reduce((accumulator, current) => {
    const event = new Date(current.time);
    const eventDate = `${event.getFullYear()}${event.getMonth()}${event.getDate()}`;
    // If there's not event for this date, add a date group
    if (!accumulator.hasOwnProperty(eventDate)) {
      accumulator[eventDate] = [current];
      return accumulator;
    }
    // Otherwise push this event into a currently existing date group
    accumulator[eventDate].push(current);
    // Sort the other events by date
    accumulator[eventDate].sort(
      (eventA, eventB) =>
        new Date(eventA.time).getTime() - new Date(eventB.time).getTime()
    );
    return accumulator;
  }, {});

  const renderEvents = (currentLocale) => {
    const title = {
      en: "titleEn",
      es: "titleEs",
      fr: "titleFr",
    };

    // Shows a comma separated list of the participants or a comming soon message
    const renderSpeakers = (participants = []) => {
      return (
        <>
          <span className="body-strong">
            {intl.formatMessage({
              id: "allEvents.speakers",
            })}
            &nbsp;
          </span>
          {participants.length > 0 && participants[0] !== "null" ? (
            <span>{participants.sort().join(", ")}</span>
          ) : (
            <span>
              {intl.formatMessage({
                id: "allEvents.comingSoon",
              })}
            </span>
          )}
        </>
      );
    };

    const eventGroups = Object.keys(groupedEvents);
    eventGroups.sort((a, b) => parseInt(a) - parseInt(b));

    return eventGroups.map((group) => (
      <EventGroup>
        <EventDate>
          {/* Show the date of the first event in each group. Should be the same as the
          others since we grouped them together by date */}
          {getDate(groupedEvents[group][0].time, currentLocale)}
        </EventDate>
        {groupedEvents[group].map((event) => (
          <Event>
            <Details>
              <Link to={`/event/${event.slug}`}>
                <h5>{event[title[currentLocale]]}</h5>
              </Link>
              <p>
                {displayTimes(
                  getTime(event.time, event.duration, currentLocale)
                )}
              </p>
              <div className="speakers-list-typo">
                <div>{renderSpeakers(event.speaker)}</div>
                {/* Documents*/}
                {event[`documents_${currentLocale}`] &&
                  event[`documents_${currentLocale}`]
                    .filter((doc) => doc.featured === true)
                    .map((doc) => (
                      <div className="event-agenda">
                        <a
                          className="download"
                          href={doc[`docLink${startCase(currentLocale)}`]}
                        >
                          {doc.title}
                        </a>
                      </div>
                    ))}
              </div>
            </Details>
          </Event>
        ))}
      </EventGroup>
    ));
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Polygon outline={ilo_yellow}>
          <Section>
            <h3>{title}</h3>
            {renderEvents(currentLocale)}
          </Section>
        </Polygon>
      )}
    </IntlContextConsumer>
  );
}

export default function EventsList({ ...rest }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allEventJson {
            nodes {
              status
              titleEn
              titleEs
              titleFr
              time
              region
              speaker
              duration
              slug
              documents_fr {
                docLinkFr
                featured
                title
              }
              documents_es {
                docLinkEs
                featured
                title
              }
              documents_en {
                docLinkEn
                featured
                title
              }
            }
          }
        }
      `}
      render={(data) => <Events data={data.allEventJson.nodes} {...rest} />}
    />
  );
}

EventsList.propTypes = {
  title: PropTypes.string.isRequired,
  global: PropTypes.string,
};

EventsList.defaultProps = {
  global: false,
};
