import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import media from "src/styled/responsive";

const STROKE = 2;

export const Wrapper = styled.div`
  position: relative;
  display: inherit;
  overflow: visible;
  .polygon-border {
    stroke-width: ${STROKE};
  }
`;

export const SVG = styled.svg`
  top: 0;
  left: 0;
  pointer-events: none;
  fill: transparent;
  stroke: ${({ outline }) => outline};
  position: absolute;

  ${media.xs`
    display: none;
  `}
`;

function Polygon({ children, outline }) {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const polygon = useRef(null);

  function handleResize() {
    if (polygon.current !== null) {
      const { width, height } = polygon.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("reisze", handleResize);
  }, []);

  const topLeft = `${STROKE}, ${STROKE}`;
  const topRight1 = `${size.width - 30 - STROKE}, ${STROKE}`;
  const topRight2 = `${size.width - STROKE}, ${30 - STROKE}`;
  const bottomRight = `${size.width - STROKE}, ${size.height - STROKE}`;
  const bottomLeft = `${STROKE}, ${size.height - STROKE}`;

  return (
    <Wrapper ref={polygon}>
      {children}
      <SVG
        outline={outline}
        width={size.width}
        height={size.height}
        viewBox={`0 0 ${size.width} ${size.height}`}
      >
        <polygon
          className="polygon-border"
          points={`${topLeft} ${topRight1} ${topRight2} ${bottomRight} ${bottomLeft}`}
        />
      </SVG>
    </Wrapper>
  );
}

export default Polygon;
