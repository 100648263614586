import React from "react";
import styled from "styled-components";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Title from "../components/title";
import Button from "../components/button";
import EventsList from "src/components/eventsList.js";
import { color } from "src/styled/variables";
import media from "src/styled/responsive";

const { ilo_yellow } = color;

const ContentWrapper = styled.div`
  margin: 1.5rem 0 0;

  ${media.sm`
    margin: 0;
  `}
`;

const StyledSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 2rem 0 0;

  ${media.sm`
    flex-flow: column-reverse;
  `}

  ${media.xs`
    padding: 0;
  `}
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  ${media.sm`
    align-self: flex-end;
    padding-bottom: 40px;
  `}
`;

// const StyledButton = styled(Button)`
//   margin: 0 0 0 0.5rem;

//   ${media.sm`
//     display: none;
//   `}
// `;

const Programme = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Title />
      <ContentWrapper>
        <Grid>
          <Row between="lg">
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={12}>
              <StyledSection>
                <h2>
                  {intl.formatMessage({ id: "teaser.home.fullprogramme" })}
                </h2>
                <Buttons>
                  <Button
                    skin="blue"
                    shape="polygon"
                    outline={ilo_yellow}
                    onClick={() => navigate("/speakers")}
                  >
                    {intl.formatMessage({
                      id: "teaser.home.speakers.allspeakers",
                    })}
                  </Button>

                  {/*<StyledButton
                    skin="grey"
                    shape="normal"
                    onClick={() =>
                      (window.location.href = intl.formatMessage({
                        id: "teaser.home.practicalInfo.url",
                      }))
                    }
                  >
                    {intl.formatMessage({
                      id: "teaser.home.practicalInfo.title",
                    })}
                  </StyledButton>*/}
                </Buttons>
              </StyledSection>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={12}>
              <p>{intl.formatMessage({ id: "programme.localTimes" })}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={6}>
              <EventsList
                title={intl.formatMessage({ id: "events.regional" })}
              />
            </Col>
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={6}>
              <EventsList
                title={intl.formatMessage({ id: "events.global" })}
                global
              />
            </Col>
          </Row>
        </Grid>
      </ContentWrapper>
    </Layout>
  );
};

export default Programme;
